.game-tracks-panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 999;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-in-out;
  transform-origin: top right;

  &__inner {
    position: absolute;
    box-sizing: border-box;
    z-index: 3;
    border-radius: 12px;
    backdrop-filter: var(--primary-backdrop-filter-light);
    -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
    background: rgba(var(--primary-component-background), 0.3);
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;

    opacity: 1;
    transition: opacity 300ms ease-in-out;

    @media screen and (pointer: fine) {
      overflow: scroll;

      justify-content: flex-start;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      // hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen and (pointer: coarse) {
      flex-direction: column;
      background: none;
      justify-content: center;
    }

    &.scheduled-race {
      background: rgba(var(--primary-component-background), 0.6);
      backdrop-filter: var(--primary-backdrop-filter);
      -webkit-backdrop-filter: var(--primary-backdrop-filter);
    }
    .game-tracks-panel__inner-container {
      display: flex;
      gap: 30px;
      box-sizing: border-box;
      width: 100%;
      min-width: 1120px;
      position: relative;
      z-index: 3;
      align-items: center;
      flex-direction: column;
      perspective: 1000px;

      @media screen and (pointer: fine) {
        padding: 60px 30px;
        justify-content: flex-start;
        transform-origin: top left;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        scroll-behavior: smooth;
      }

      @media screen and (pointer: coarse) {
        padding: 0 30px;
        gap: 0;
        justify-content: center;
        transform-origin: center left;
      }

      .game-tracks-panel__tracks {
        min-height: 422px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        @media screen and (pointer: coarse) {
          padding: 30px 0 60px;
          min-height: auto;
          background: rgba(var(--primary-component-background), 0.6);
          backdrop-filter: var(--primary-backdrop-filter-light);
          -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
          flex: 1;
        }
      }

      @media screen and (pointer: coarse) {
        transform-origin: top right;
        min-width: unset;
        overflow: scroll;
        align-items: center;
        justify-content: flex-start;
        height: 100dvh;
        padding: 0;
      }

      .game-tracks-panel__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        color: rgba(255, 255, 255, 0.8);
        gap: 10px;
        height: 300px;

        .loading-spinner {
          border: 4px solid rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          border-top: 4px solid rgb(var(--yellow));
          width: 40px;
          height: 40px;
          margin: 0 auto 1rem;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .game-tracks-panel__button-container {
        display: flex;
        gap: 10px;

        @media screen and (pointer: coarse) {
          position: sticky;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          box-sizing: border-box;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          background: rgba(var(--primary-component-background), 0.6);
          backdrop-filter: var(--primary-backdrop-filter-light);
          -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
          z-index: 9;
        }

        .game-tracks-panel__button {
          z-index: 3;
          top: 0;
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
          backdrop-filter: var(--primary-backdrop-filter-light);
          -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
          background: rgba(var(--primary-component-background), 0.6);
          height: 44px;
          border-radius: 999em;
          user-select: none;
          appearance: none;
          font-weight: 600;
          font-size: 12px;
          color: rgba(var(--primary-text-color), 0.8);
          font-family: var(--font-formula-stack);
          cursor: pointer;
          transition: all 300ms ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          gap: 6px;
          animation: reveal-opacity 500ms ease-in-out;

          svg {
            width: 16px;
            height: 16px;
            fill: currentColor;
          }
          &:hover {
            color: rgba(var(--yellow), 1);
          }

          &.left-button {
            svg:first-child {
              width: 12px;
              height: 12px;
            }

            .ico-marbles-symbol_svg__ico-marbles-symbol {
              width: 30px;
              height: 30px;
              transition: all 500ms ease-in-out;
              transform-style: preserve-3d;
              transform-origin: center;
              animation: rotate-symbol 3s cubic-bezier(0.4, 0, 0.2, 1);
            }

            @keyframes rotate-symbol {
              0% {
                transform: rotate(0deg);
              }
              33% {
                transform: rotate(-40deg);
                scale: 1.05;
              }
              66% {
                transform: rotate(40deg);
                scale: 0.95;
              }
              100% {
                transform: rotate(0deg);
                scale: 1;
              }
            }

            &:hover {
              .ico-marbles-symbol_svg__ico-marbles-symbol {
                transform: rotateY(180deg);

                rect {
                  stroke: rgb(var(--marbles-yellow));
                }
                path {
                  fill: rgb(var(--marbles-yellow));
                  stroke: rgb(var(--marbles-yellow));
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 768px) and (pointer: coarse) {
        .game-lobby-races {
          padding: 0 10px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;
        }
      }

      .game-lobby-races_entry {
        &.current-game {
          background: linear-gradient(
            160.99deg,
            rgb(var(--yellow)) 2.56%,
            rgba(var(--primary-component-background), 1) 72.32%
          );
        }
        &.race_started {
          .track-events {
            .time {
              display: none;
            }
          }
        }
        .track-name {
          animation: none;
          color: rgba(255, 255, 255, 0.8);
          background: none;
          background-clip: unset;
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;
        }
      }

      @media screen and (pointer: coarse) {
        .game-lobby-races_entry {
          max-height: 340px;
          border-radius: 20px 20px 12px 12px;

          .entry-content_inner {
            padding: 5px;
            border-radius: 20px 20px 12px 12px;

            &:before {
              border-radius: 20px 20px 12px 12px;
            }
          }

          .entry-image-container,
          .entry-image {
            border-radius: 18px 18px 12px 12px;
          }
          .entry-bottom {
            text-align: center;
            align-items: center;
          }
          .track-name {
            font-size: 16px;
          }

          .track-events {
            margin-top: 6px;
            .time {
              display: none;
            }
          }

          .entry-content_inner {
            padding-bottom: 15px;
          }
        }
      }

      .game-tracks-panel__tracks-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        animation: reveal-opacity 500ms ease-in-out;

        .primary-heading {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;

          &.schedule-info {
            flex-direction: column;
            gap: 6px;

            box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
            backdrop-filter: var(--primary-backdrop-filter-light);
            -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
            background: rgba(var(--primary-component-background), 0.6);
            padding: 10px 14px;
            border-radius: 16px;

            .schedule-info__timer {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
            }
            .icon {
              svg {
                fill: rgba(var(--blue));
                width: 24px;
                height: 24px;
              }
            }
            h2 {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              font-variant-numeric: tabular-nums;
              font-size: 18px;
            }
            h3 {
              font-size: 16px;
              text-transform: uppercase;
              color: rgba(var(--yellow));
            }

            .time {
              svg {
                display: none;
              }
            }
          }
        }

        h2 {
          font-size: 24px;
          font-weight: 600;
          font-family: var(--font-formula-stack);
        }

        h3 {
          font-size: 18px;
          font-weight: 600;
          color: rgb(var(--yellow));
          font-family: var(--font-formula-stack);
        }

        @media screen and (pointer: coarse) {
          padding: 0 30px;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 34px;
            height: 34px;
            fill: rgba(var(--yellow));
          }
        }
      }
    }
  }
}
